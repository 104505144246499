import React, { useState, useContext, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import Observer from '../observer'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'

export const fragment = graphql`
  fragment ServicesListFragment on WordPress_Service_Servicepagefields {
    featuredInfos {
      subtitle
      title
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const ServiceItemComp = ({ service, index, refComp }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animationToggle, setAnimationToggle] = useState(false)
  const ref = useRef()

  const buttonAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1100,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Observer element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
      <ServiceItem ref={refComp}>
        <WrapItem>
          <ItemDesc>
            <h2> {service.node.servicepageFields.featuredInfos.title} </h2>
            <p> {service.node.servicepageFields.featuredInfos.subtitle} </p>
            <ServicesButton
              style={buttonAnimation}
              to={`/services/${service.node.slug}`}
              onClick={event => {
                event.preventDefault()
                setTransitionActive(true)
                setTimeout(() => {
                  navigate(`/services/${service.node.slug}`)
                  setTransitionActive(false)
                }, 500)
              }}
            >
              Learn More
            </ServicesButton>
          </ItemDesc>
          <WrapServiceImage>
            <Img
              style={{ position: 'absolute' }}
              fluid={
                service.node.servicepageFields.featuredInfos.image.imageFile.childImageSharp.fluid
              }
            />
          </WrapServiceImage>
          <ServicesButtonMobile
            style={buttonAnimation}
            to={`/services/${service.node.slug}`}
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/services/${service.node.slug}`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            Learn More
          </ServicesButtonMobile>
        </WrapItem>
      </ServiceItem>
    </Observer>
  )
}

const WrapItem = styled(animated.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const WrapServiceImage = styled.div`
  position: relative;
  width: 55%;
  height: 100%;

  @media (max-width: 1024px) {
    width: 55%;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`

const ServiceItem = styled(animated.div)`
  width: 100%;
  margin-bottom: 15rem;

  ${WrapServiceImage} {
    ${aspectRatio(376, 274)}

    > div:first-of-type {
      width: 110% !important;
      height: 110% !important;
      left: -6% !important;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
  }

  img {
    object-fit: contain !important;
  }
`

const ItemDesc = styled(animated.div)`
  text-align: left;
  margin: 0;
  width: 40%;

  @media (max-width: 650px) {
    width: 100%;
  }

  span {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 1.75rem;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 5.5rem;
    letter-spacing: -0.075rem;

    @media (max-width: 650px) {
      font-size: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;

    @media (max-width: 1024px) {
      font-size: 2rem;
      margin: 0 auto;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 2.3rem;
    }
  }
`

const ServicesButton = styled(animated(Link))`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.pink};
  padding: 1.8rem 1.25rem;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 2.5rem;
  min-width: 16rem;
  text-align: center;
  display: inline-block;
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 769px) {
    display: none;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }
`

const ServicesButtonMobile = styled(animated(Link))`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.pink};
  padding: 1.8rem 1.25rem;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 2.5rem;
  min-width: 20rem;
  text-align: center;
  display: none;
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 769px) {
    display: inline-block;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }
`

export default ServiceItemComp
