import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ServicesHero from '../components/services/hero'
import SEO from '../components/seo'

const Services = ({ data, pageContext }) => (
  <Layout>
    <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.pageBy.seo} />
    <ServicesHero
      hero={data.wordPress.pageBy.servicesFields.heroInfos}
      services={data.wordPress.services.edges}
    />
  </Layout>
)

export default Services

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        servicesFields {
          ...ServicesHeroFragment
        }
      }
      services(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
        edges {
          node {
            slug
            servicepageFields {
              ...ServicesListFragment
            }
          }
        }
      }
    }
  }
`
