import React, { useContext, useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, useTrail, animated } from 'react-spring'
import { ButtonContext } from '../context/button-context'
import InView from '../in-view'

import { ScrollbarContext } from '../context/scrollbar-context'

import ArrowPinkSvg from '../../images/arrow-pink.svg'

import ServiceItemComp from './serviceItem'
import HeroFlowmap from '../hero-flowmap'

export const fragmentQl = graphql`
  fragment ServicesHeroFragment on WordPress_Page_Servicesfields {
    heroInfos {
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
        }
      }
      imageMobile {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      title
      text
      introText
    }
  }
`
export const fragmentQl2 = graphql`
  fragment ServicesListFragment on WordPress_Service_Servicepagefields {
    featuredInfos {
      subtitle
      title
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const ServicesHero = ({ hero, services }) => {
  const triggerRef = useRef()
  const [isDesktop, setIsDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const [scrollbar] = useContext(ScrollbarContext)

  const [animationToggle, setAnimationToggle] = useState(false)
  const ref = useRef()
  const itemEls = useRef([])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const servicesItemAnimation = useTrail(services.length, {
    opacity: animationToggle ? 1 : 0,
    x: animationToggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175
    },
    delay: animationToggle ? 300 : 50
  })

  const linksItemAnimation = useTrail(services.length, {
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175
    },
    delay: 2100
  })

  const textAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1100,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <>
      <OuterHero>
        {!isDesktop && (
          <InView element={triggerRef.current} setToggle={setButtonTriggers} location="hero">
            <TriggerButton ref={triggerRef} />
          </InView>
        )}
        <WrapHero>
          <HeroFlowmap
            mobile={hero.imageMobile.imageFile.childImageSharp.fluid}
            desktop={hero.image.imageFile.publicURL}
          />
          <WrapText>
            <animated.h2
              style={textAnimation}
              dangerouslySetInnerHTML={{ __html: hero.introText }}
            />
            <WrapLinkTo>
              {linksItemAnimation.map(({ x, ...rest }, index) => {
                const serviceItem = services[index]
                return (
                  <LinkTo
                    ref={element => {
                      itemEls.current[index] = element
                    }}
                    key={`linto-${index}`}
                    style={{
                      ...rest,
                      // eslint-disable-next-line no-shadow
                      transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                    }}
                    onClick={() => {
                      if (scrollbar && isDesktop) {
                        scrollbar.scrollTo(
                          0,
                          itemEls.current[index].getBoundingClientRect().top - 100,
                          450 * (index + 1)
                        )
                      } else {
                        const topEl = itemEls.current[index].getBoundingClientRect().top - 50
                        window.scrollTo({ top: topEl, behavior: 'smooth' })
                      }
                    }}
                  >
                    <ArrowPinkSvg />
                    <span>{serviceItem.node.servicepageFields.featuredInfos.title}</span>
                  </LinkTo>
                )
              })}
            </WrapLinkTo>
          </WrapText>
        </WrapHero>
      </OuterHero>

      <Outer>
        <ServiceBlock>
          <ServicesFeatured ref={ref}>
            {servicesItemAnimation.map(({ x, ...rest }, index) => {
              const serviceItem = services[index]
              return (
                <ServiceItemComp
                  key={serviceItem.node.servicepageFields.featuredInfos.subtitle}
                  refComp={element => {
                    itemEls.current[index] = element
                  }}
                  service={serviceItem}
                  index={index}
                />
              )
            })}
          </ServicesFeatured>
        </ServiceBlock>
      </Outer>
    </>
  )
}

export default ServicesHero

const OuterHero = styled.section`
  position: relative;
  height: 100%;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 650px) {
    height: 100%;
    padding-bottom: 12rem;
  }
`

const Outer = styled.section`
  position: relative;
  height: 100%;
`

const TriggerButton = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
  height: 1px;
  background-color: transparent;
`

const WrapHero = styled.div`
  position: relative;
  height: 100%;
`

const WrapText = styled.div`
  position: absolute;
  width: calc(42% - 10rem);
  height: auto;
  top: 50%;
  right: 18rem;
  transform: translateY(-50%);

  @media (max-width: 1024px) {
    position: relative;
    top: 0;
    left: 0;
    width: 80%;
    margin: 7rem auto;
    transform: translateY(0);
    text-align: center;
  }

  @media (max-width: 650px) {
    width: 90%;
    top: 2.5rem;
  }

  h1 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 5rem;
    line-height: 1.15;
    letter-spacing: -0.075rem;
    margin-bottom: 3rem;
    max-width: 17ch;

    @media (max-width: 1024px) {
      font-size: 5rem;
      line-height: 1.25;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 650px) {
      margin-bottom: 5rem;
    }
  }

  h2 {
    p {
      color: ${props => props.theme.colours.grey};
      font-family: ${props => props.theme.fonts.circular};
      font-weight: 300;
      font-size: 2.2rem;
      line-height: 1.5;
      max-width: 65ch;

      @media (max-width: 1024px) {
        font-size: 2rem;
        margin: 0 auto;
      }

      @media (max-width: 650px) {
        font-size: 2.58rem;
        line-height: 1.45;
      }

      strong {
        color: ${props => props.theme.colours.pink};
        font-weight: 300;
      }
    }
  }
`

const WrapLinkTo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5rem;

  /* @media (max-width: 1024px) {
    margin-top: 10rem;
  } */

  @media (max-width: 1024px) {
    margin-top: 8rem;
    display: none;
  }
`

const LinkTo = styled(animated.div)`
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 500;
  font-size: 1.8rem;
  position: relative;
  margin: 0 0 1.5rem 0;
  cursor: pointer;
  width: 45%;
  display: flex;

  svg {
    margin-top: 0.35rem;
  }

  span {
    font-weight: 600;
    margin-left: 1rem;
  }
`

const ServiceBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

const ServicesFeatured = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};

  @media (max-width: 650px) {
    max-width: calc(100% - 50px);
  }
`
